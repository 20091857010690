import axios from 'axios'
import storage from '../storages/UserStorage'
import { getUrlBase } from '../utils/functions'
import preval from 'preval.macro'

const ApiNoblier = {}

const urlBase = getUrlBase(true)

ApiNoblier.url = urlBase
ApiNoblier.urlPublic = urlBase

ApiNoblier.token = function () {
  const user = storage.getItemJson()
  if (!user?.access_token) {
    return (window.location.href = '/')
  }

  return user['access_token']
}

ApiNoblier.request = axios.create({
  baseURL: urlBase,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    hspw: JSON.stringify({
      mobile: false,
      versao: preval`module.exports = new Date().getTime();`
    }),
    tzoffset: new Date().getTimezoneOffset()
  },
  validateStatus: function (status) {
    return status >= 200 && status <= 500
  }
})

ApiNoblier.authRequest = token =>
  axios.create({
    baseURL: `${urlBase}/auth`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      hspw: JSON.stringify({
        mobile: false,
        versao: preval`module.exports = new Date().getTime();`
      }),
      tzoffset: new Date().getTimezoneOffset()
    },
    validateStatus: function (status) {
      return status >= 200 && status <= 500
    }
  })

export default ApiNoblier
