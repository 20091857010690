import React, { useEffect } from 'react'
import { io } from 'socket.io-client'
import ApiNoblier from '../services/aAPiNoblier'
import { getUrlBase } from '../utils/functions'
import { toast as toaster } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { PedidoAjuda } from '../assets/icons'

function SocketToast({ userReducer }) {
  const [mensagens, setMensagens] = React.useState([])
  const history = useHistory()

  const messageInfo = (qtde, sender) => (
    <div>
      <div style={{ fontSize: '13px', fontWeight: 'bold' }}>
        Pedido de ajuda
      </div>
      <p style={{ margin: 0 }}>
        Você tem <b>{qtde}</b> {qtde == 1 ? 'nova mensagem' : 'novas mensagens'}{' '}
        de <b>{sender}</b>
      </p>
    </div>
  )

  function navigateToChat(chat, isAdmin) {
    const path = isAdmin ? 'admin' : 'admin-company'
    history.push(`/${path}/company-chat-request/${chat}`)
  }

  useEffect(() => {
    if (userReducer.user) {
      const client = io(getUrlBase(true), {
        extraHeaders: {
          authorization: `Bearer ${ApiNoblier?.token()}`
        }
      })

      const socketObject = {
        chat: userReducer.user.empresa
      }

      client.emit('enterChat', socketObject)

      client.on('receivedNotification', data => {
        if (
          (history.location &&
            history.location.pathname?.includes(data.chat_id)) ||
          history.location.search?.includes(data.chat_id)
        ) {
          return
        }
        if (data.sender !== 'admin') updateToasts(data.chat_id, data.sender)
      })

      const updateToasts = (chat_id, sender) => {
        const toasts = mensagens && mensagens.length > 0 ? [...mensagens] : []
        const idx = toasts.findIndex(t => t.chat_id == chat_id)
        if (idx >= 0 && toasts[idx].toastId) {
          toaster.update(toasts[idx].toastId, {
            render: messageInfo((toasts[idx].qtde ?? 1) + 1, sender)
          })
          toasts[idx].qtde = toasts[idx].qtde + 1
        } else {
          const id = toaster.info(messageInfo(1, sender), {
            autoClose: false,
            icon: <PedidoAjuda />,
            onClick: () => {
              navigateToChat(chat_id, userReducer.user.admin)
              setMensagens({
                mensagens: toasts.filter(m => m.chat_id != chat_id) ?? []
              })
            },
            onClose: () => {
              setMensagens({
                mensagens: toasts.filter(m => m.chat_id != chat_id) ?? []
              })
            }
          })
          toasts.push({ chat_id, toastId: id, qtde: 1 })
        }
        setMensagens([...toasts] ?? [])
      }

      return () => client.off('receivedNotification')
    }
  }, [userReducer.user, history.location, mensagens])

  return <></>
}

const mapStateToProps = state => ({
  userReducer: state.userStore.user
})

export default connect(mapStateToProps)(SocketToast)
