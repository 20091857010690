import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import { comboOptions } from './constants'
require('dotenv').config()

export const HSPW = underline => {
  return (
    <span className={`fa-main-bold ${underline ? 'fa-underline' : ''}`}>
      HSPW
    </span>
  )
}
export const bold = texto => {
  return <span className={'fa-bold'}>{texto}</span>
}
export const underline = (texto, bold = false) => {
  return <span className={`fa-underline ${bold ? 'bold' : ''}`}>{texto}</span>
}
export const mainBold = texto => {
  return <span className={'fa-main-bold'}>{texto}</span>
}

export const traduzPercentToScore = (percentual, escala_severidade) => {
  if (!percentual) return '0'

  let tmp = parseFloat(percentual)
  return isNaN(tmp)
    ? '0'
    : escala_severidade === 'CRESCENTE'
    ? tmp < 25
      ? '1'
      : tmp < 50
      ? '2'
      : tmp < 75
      ? '3'
      : '4'
    : tmp < 25
    ? '4'
    : tmp < 50
    ? '3'
    : tmp < 75
    ? '1'
    : '1'
}

export const traduzScoreToPercent = (score, escala_severidade) => {
  let tmp = parseInt(score)
  return isNaN(tmp)
    ? '0'
    : escala_severidade === 'CRESCENTE'
    ? tmp === 1
      ? '0'
      : tmp === 2
      ? '50'
      : tmp === 3
      ? '75'
      : '100'
    : tmp === 1
    ? '100'
    : tmp === 2
    ? '75'
    : tmp === 3
    ? '50'
    : '0'
}

export const formatPhoneNumber = str => {
  const cleaned = ('' + str).replace(/\D/g, '').substring(0, 11)
  const match = cleaned.match(/^(\d{2})(\d{5})(\d{4})$/)
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3]
  }

  return str
}

export const sortItemOptionsAnswer = item => {
  function isOpcaoField(field = '') {
    return field.indexOf('opcao') > -1
  }

  function formatArray(item) {
    let arr = []

    for (const key in item) {
      if (isOpcaoField(key)) {
        let campoOrdem = 'ordem_' + key.replace(/\D/g, '')

        let obj = {
          opcao: key.replace(/\D/g, ''),
          valor_ordem: item[campoOrdem]
        }

        arr = [...arr, obj]
      }
    }

    return arr
  }

  const formattedArr = formatArray(item)

  const sortedArr = _.orderBy(formattedArr, 'valor_ordem', 'asc')

  return sortedArr.map(item => item.opcao)
}

export const dateTimeFormat = date => {
  return moment(date).format('DD/MM/YYYY HH:mm:ss')
}

export const dateFormat = date => {
  return moment(date).format('DD/MM/YYYY')
}

export function sanitizeToSort(str) {
  return str
    ? typeof str === 'number'
      ? parseInt(str)
      : str
          .toString()
          .replace(/\s+/g, '')
          .normalize('NFD') // REMOVE ACCENTED CHARS
          .replace(/[\u0300-\u036f]/g, '') // REMOVE DIACRITICS
          .toLowerCase()
    : '' //str
}

export function sortByProperty(arr, property, sortASC) {
  arr.forEach(item => (item.tempProp = sanitizeToSort(item[property])))
  arr.sort((a, b) =>
    sortASC
      ? a.tempProp > b.tempProp
        ? 1
        : a.tempProp < b.tempProp
        ? -1
        : 0
      : a.tempProp > b.tempProp
      ? -1
      : a.tempProp < b.tempProp
      ? 1
      : 0
  )
  arr.forEach(item => delete item.tempProp)
  return arr
}

export function getUrlBase(api = false) {
  const AMBIENTE = process.env.REACT_APP_AMBIENTE || 'local'
  let url = ''
  switch (AMBIENTE) {
    case 'prod':
      url = `https://${api ? 'api' : 'app'}.hspw.com${api ? '' : '/'}`
      break
    case 'hml':
      url = `https://${api ? 'api-hml' : 'hml'}.hspw.com${api ? '' : '/'}`
      break
    case 'qa':
      url = `https://${api ? 'api-qa' : 'qa'}.hspw.com${api ? '' : '/'}`
      break
    case 'dev':
      url = `http://${process.env.IP_DEV || 'localhost'}:${
        api ? process.env.PORT_API || 3000 : `${process.env.PORT || 3001}/`
      }`
      break
    default:
      // Nenhum dos ambientes previstos: desenvolvimento local.
      url = `http://localhost:${api ? '3000' : '3001/'}`
  }

  return url
}

export function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export function removerAcentos(s) {
  return s !== '' ? s.normalize('NFD').replace(/[\u0300-\u036f]/g, '') : s
}

export function middleElipsis(string, maxLength) {
  if (!string) return string
  if (maxLength < 1) return string
  if (string.length <= maxLength) return string
  if (maxLength === 1) return string.substring(0, 1) + '...'

  var midpoint = Math.ceil(string.length / 2)
  var toremove = string.length - maxLength
  var lstrip = Math.ceil(toremove / 2)
  var rstrip = toremove - lstrip
  return (
    string.substring(0, midpoint - lstrip) +
    '...' +
    string.substring(midpoint + rstrip)
  )
}

export function formataNumero(number) {
  return new Intl.NumberFormat('pt-BR').format(number)
}

export function convertUTCStringToLocalDate(UTCDate) {
  var date = new Date(UTCDate)
  var localDate = new Date(
    date.getTime() - date.getTimezoneOffset() * 60 * 1000
  )
  return {
    date: localDate,
    formattedTime: localDate.toLocaleString('pt-BR', {
      hour: 'numeric',
      minute: 'numeric'
    }),
    formattedDate: localDate.toLocaleString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    })
  }
}

export function hasSpecialCharacters(str) {
  return /[~`!@#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(str) // eslint-disable-line
}

export function hasNumber(str) {
  return /\d/.test(str)
}

export function hasUpperCase(str) {
  return /[A-Z]/.test(str)
}

export function isObjectId(id) {
  return id && id != '' ? id.match(/^[0-9a-fA-F]{24}$/) : false
}

export function formataPeriodicidade(frequencia, valor) {
  if (['DIAS', 'MINUTOS', 'HORAS'].includes(frequencia)) {
    if (valor === 1) {
      if (frequencia === 'DIAS') return 'Diariamente'
      else return `A cada ${valor} ${frequencia.toLowerCase().replace('s', '')}`
    } else return `A cada ${valor} ${frequencia.toLowerCase()}`
  } else
    return comboOptions.frequenciaAplicacaoTriagem.map(
      o => o.value == frequencia && o.label
    )
}

export function renderSituacaoBadge({
  situacao,
  genero = 'M',
  removido = false
}) {
  return (
    <div
      className={`badge badge-${
        removido == true
          ? 'danger'
          : situacao == 'ATIVO'
          ? 'success'
          : situacao == 'INATIVO'
          ? 'danger'
          : 'warning'
      }  px-1 my-auto mr-2`}
    >
      {removido == true
        ? 'Removid' + (genero == 'M' ? 'o' : 'a')
        : situacao == 'ATIVO'
        ? 'Ativ' + (genero == 'M' ? 'o' : 'a')
        : situacao == 'INATIVO'
        ? 'Inativ' + (genero == 'M' ? 'o' : 'a')
        : 'Rascunho'}
    </div>
  )
}

/**
 * Método inicialmente criado para atender o calculo da data final de um ciclo de Triagem.
 */

function getUnitAmount(frequencia, valor) {
  const unit =
    frequencia == 'MINUTOS'
      ? 'minutes'
      : frequencia == 'HORAS'
      ? 'hours'
      : frequencia == 'DIAS' || frequencia == 'DIARIA'
      ? 'days'
      : frequencia == 'SEMANAL' ||
        frequencia == 'TRISSEMANAL' ||
        frequencia == 'QUINZENAL'
      ? 'weeks'
      : frequencia == 'MENSAL' || frequencia == 'SEMESTRAL'
      ? 'months'
      : 'year'

  const amount =
    (valor && valor != '' && frequencia == 'MINUTOS') ||
    frequencia == 'HORAS' ||
    frequencia == 'DIAS'
      ? valor
      : 1

  return { unit, amount }
}

export function getDataLimite({
  data_inicio,
  frequencia,
  valor,
  fator,
  returnMoment = false
}) {
  const { unit, amount } = getUnitAmount(frequencia, valor)

  /* var ms =
    (frequencia == 'MINUTOS'
      ? 60000
      : frequencia == 'HORAS'
      ? 3600000
      : frequencia == 'DIAS' || frequencia == 'DIARIA'
      ? 86400000
      : frequencia == 'SEMANAL'
      ? 604800000
      : frequencia == 'TRISSEMANAL'
      ? 1814400000
      : frequencia == 'QUINZENAL'
      ? 1209600000
      : frequencia == 'MENSAL'
      ? 2592000000
      : frequencia == 'SEMESTRAL'
      ? 15552000000
      : // ANUAL
        31536000000) *
    nValor *
    fator */

  var ret = moment(data_inicio)
    .add(amount * fator, unit)
    .subtract(1, 'second')
  return fator > 0
    ? returnMoment
      ? ret
      : ret.format('DD/MM/YYYY HH:mm:ss')
    : null
}

export function getDataInicioTriagem({
  data_inicio_ciclo,
  frequencia,
  valor,
  sequencia
}) {
  const { unit, amount } = getUnitAmount(frequencia, valor)

  const ret = moment(data_inicio_ciclo)

  return (
    sequencia == 1 ? ret : ret.add(amount * (sequencia - 1), unit)
  ).format('DD/MM/YYYY HH:mm:ss')
}

export function s2ab(s) {
  var buf = new ArrayBuffer(s.length)
  var view = new Uint8Array(buf)
  for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff
  return buf
}

export function getMaskedCardNumber(card_number) {
  // ${1 digit} ${4 digits} ${6 digits} ${1 digit}
  var strCardNumber = card_number?.toString()
  return strCardNumber?.length === 12
    ? strCardNumber.substring(0, 1) +
        ' ' +
        strCardNumber.substring(1, 5) +
        ' ' +
        strCardNumber.substring(5, 11) +
        ' ' +
        strCardNumber.substring(11, 12)
    : strCardNumber
}

export function generateRandomCPF() {
  const randomDigit = () => Math.floor(Math.random() * 10)

  const generateDigits = length => {
    let digits = ''
    for (let i = 0; i < length; i++) {
      digits += randomDigit()
    }
    return digits
  }

  const calculateDigit = cpf => {
    let sum = 0
    let weight = cpf.length + 1
    for (let i = 0; i < cpf.length; i++) {
      sum += parseInt(cpf.charAt(i)) * weight
      weight--
    }
    let digit = 11 - (sum % 11)
    return digit > 9 ? 0 : digit
  }

  const cpf = generateDigits(9)
  const digit1 = calculateDigit(cpf)
  const digit2 = calculateDigit(cpf + digit1)

  return cpf + digit1 + digit2
}

export function validateCPF(cpf) {
  if (!cpf) return false

  cpf = cpf.toString().replace(/\D/g, '')

  if (cpf.length !== 11) return false

  if (
    cpf === '00000000000' ||
    cpf === '11111111111' ||
    cpf === '22222222222' ||
    cpf === '33333333333' ||
    cpf === '44444444444' ||
    cpf === '55555555555' ||
    cpf === '66666666666' ||
    cpf === '77777777777' ||
    cpf === '88888888888' ||
    cpf === '99999999999'
  )
    return false

  let sum = 0
  let rest

  for (let i = 1; i <= 9; i++)
    sum = sum + parseInt(cpf.substring(i - 1, i)) * (11 - i)
  rest = (sum * 10) % 11

  if (rest === 10 || rest === 11) rest = 0
  if (rest !== parseInt(cpf.substring(9, 10))) return false

  sum = 0
  for (let i = 1; i <= 10; i++)
    sum = sum + parseInt(cpf.substring(i - 1, i)) * (12 - i)
  rest = (sum * 10) % 11

  if (rest === 10 || rest === 11) rest = 0
  if (rest !== parseInt(cpf.substring(10, 11))) return false

  return true
}

export function formatCPF(cpf) {
  if (!cpf) return ''
  cpf = cpf.toString().replace(/\D/g, '')
  return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
}

export function sanitizeText2(text) {
  return text
    ?.toString()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .trim()
    .toLowerCase()
}

export function compareStrings2(str1, str2) {
  return sanitizeText2(str1) === sanitizeText2(str2)
}

export function getValidUF(uf) {
  if (uf?.length < 2) return ''
  const ufs = [
    { uf: 'AC', nome: 'Acre' },
    { uf: 'AL', nome: 'Alagoas' },
    { uf: 'AP', nome: 'Amapá' },
    { uf: 'AM', nome: 'Amazonas' },
    { uf: 'BA', nome: 'Bahia' },
    { uf: 'CE', nome: 'Ceará' },
    { uf: 'DF', nome: 'Distrito Federal' },
    { uf: 'ES', nome: 'Espírito Santo' },
    { uf: 'GO', nome: 'Goiás' },
    { uf: 'MA', nome: 'Maranhão' },
    { uf: 'MT', nome: 'Mato Grosso' },
    { uf: 'MS', nome: 'Mato Grosso do Sul' },
    { uf: 'MG', nome: 'Minas Gerais' },
    { uf: 'PA', nome: 'Pará' },
    { uf: 'PB', nome: 'Paraíba' },
    { uf: 'PR', nome: 'Paraná' },
    { uf: 'PE', nome: 'Pernambuco' },
    { uf: 'PI', nome: 'Piauí' },
    { uf: 'RJ', nome: 'Rio de Janeiro' },
    { uf: 'RN', nome: 'Rio Grande do Norte' },
    { uf: 'RS', nome: 'Rio Grande do Sul' },
    { uf: 'RO', nome: 'Rondônia' },
    { uf: 'RR', nome: 'Roraima' },
    { uf: 'SC', nome: 'Santa Catarina' },
    { uf: 'SP', nome: 'São Paulo' },
    { uf: 'SE', nome: 'Sergipe' },
    { uf: 'TO', nome: 'Tocantins' },
    { uf: 'FF', nome: 'Fronteiras' }
  ]
  for (const item of ufs) {
    if (compareStrings2(item.uf, uf) || compareStrings2(item.nome, uf))
      return item.uf
  }
  return ''
}

export function formatDiagnosticType(value) {
  if (value === 'SAUDE_PSICOLOGICA') return 'Saúde Psicológica'
  if (value === 'SAUDE_FISICA') return 'Saúde Física'
  if (value === 'SAUDE_SOCIAL') return 'Saúde Social'
  if (value === 'SAUDE_FINANCEIRA') return 'Saúde Financeira'
  if (value === 'SAUDE_EMPRESARIAL') return 'Saúde Empresarial'
  if (value === 'DOENCAS_PREGRESSAS') return 'Doenças Pregressas'
}

export function parentFormatter(data) {
  data === 'PAI' && (data = 'Pai')
  data === 'MAE' && (data = 'Mãe')
  data === 'IRMAO' && (data = 'Irmão(a)')
  data === 'FILHO' && (data = 'Filho(a)')
  data === 'ENTEADO' && (data = 'Enteado(a)')
  data === 'CONJUGE' && (data = 'Cônjuge')
  data === 'OUTROS' && (data = 'Outros')
  return data
}

export function insertEllipsis(str, maxLength) {
  if (!str) return str
  if (str.length <= maxLength) {
    return str
  }

  const midpoint = maxLength / 2
  const start = str.slice(0, midpoint)
  const end = str.slice(-midpoint)
  return `${start}...${end}`
}
